

































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL, ABILITY_OPTIONS } from '@/config/constant';
import { FeedbackReportService } from '@/services/abilityAssessment/feedback-report-service';
import { isEmpty, map, filter } from 'lodash';

@Component({
  components: {}
})
export default class typeManagement extends Vue {
  @Inject(FeedbackReportService)
  private feedbackReportService!: FeedbackReportService;

  public ADD: string = ADD;
  public EDIT: string = EDIT;
  public DETAIL: string = DETAIL;
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = false;
  public statusOptions: any[] = [];

  public async created(): Promise<void> {
    this.statusOptions = ABILITY_OPTIONS.evaluationStatus;
    await this.getList();
  }
  // 搜索
  public search() {
    this.form.current = 1;
    this.getList();
  }
  // 分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  // 导出
  public async exportClick() {
    // delete this.form.current;
    // delete this.form.pages;
    // delete this.form.size;
    // delete this.form.total;
    if (!this.tableData.length) {
      this.$message({
        type: 'error',
        message: '当前无下载内容！'
      })
      return;
    }
    // TODO
    console.log("导出", this.form);
    this.loading = true;
    // const res = this.feedbackReportService.mimanageExportReport({...this.form});
    this.loading = false;
  }
  //获取数据源
  public async getList() {
    console.log("form", this.form);
    this.loading = true;
    // const res = await this.feedbackReportService.queryArticleList(this.form);
    // TODO 模拟假数据
    setTimeout(() => {
      const res = {
        records: [
          {
            id: 1,
            aaa: '反馈报表1',
            bbb: '2024-10-10 09:10:10'
          },
          {
            id: 2,
            aaa: '反馈报表2',
            bbb: '2024-10-12 10:20:20'
          },
        ],
        total: 2,
        size: 10,
        current: 1
      };
      this.loading = false;
      this.tableData = res.records;
      this.form.total = res.total;
      this.form.size = res.size;
      this.form.current = res.current;
    }, 500);
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }
}
